<template>
  <v-container v-if="loaded">
    <div v-if="$route.params && $route.params.cashoutPaypalAvailable == true">
      <p v-if="hasMetRedemptionCondition() == false">
        {{ $t('points_to_redeem_min', { 0: countryConversion.minToRedeem } ) }}
      </p>
      <v-stepper v-model="e6" vertical transparent v-else>
        <v-stepper-step :complete="e6 > 0" step="0">
          {{ $t('frequently_asked_questions')}} / {{ $t('terms_conditions') }}
        </v-stepper-step>

        <v-stepper-content step="0">          
          <ol>
            <li class="pa-0 ma-0 font-weight-medium">{{$t('paypal_payout_charges')}}</li>
            <p>{{ $t('paypal_payout_charges_ans') }} </p>

            <li class="pa-0 ma-0 font-weight-medium">{{$t('paypal_payout_currency')}}</li>
            <p>{{ $t('paypal_payout_currency_ans') }} </p>

            <li class="pa-0 ma-0 font-weight-medium">{{$t('paypal_countries_availability')}}</li>
            <p v-html="$t('paypal_countries_availability_ans')"></p>

            <li class="pa-0 ma-0 font-weight-medium">{{ $t('min_req_redemption') }}</li>
            <p>{{$t('min_req_redemption_ans')}} {{ countryConversion.minToRedeem }}.</p>

            <li class="pa-0 ma-0 font-weight-medium">{{$t('points_currency_ratio')}}</li>
            <p>{{ cashOutDetails.minToRedeem }} {{ $t('points_currency_ratio_ans') }} {{ cashOutDetails.currency }} {{ Math.floor(cashOutDetails.valuePerPoint * cashOutDetails.minToRedeem) }}</p>

            <li class="pa-0 ma-0 font-weight-medium">{{$t('reward_expect')}}</li>
            <p>{{ $t('reward_expect_ans') }} </p>

            <li class="pa-0 ma-0 font-weight-medium">{{$t('contact_payout')}}</li>
            <p>{{ $t('contact_payout_ans') }} </p>
          </ol>

          <p>{{ $t('by_clicking') }}<span class="font-weight-bold">{{ $t('continue') }}</span>, {{ $t('you_have_read') }} {{ $t('frequently_asked_questions') }} / {{ $t('terms_conditions') }}.</p>

          <v-btn color="primary" @click="e6 = 1">{{$t('continue')}}</v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 1" step="1">
          {{ $t('paypal_account_details') }}
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-form ref="form" v-if="loaded && hasMetRedemptionCondition()">
            <p>{{ $t('provide_paypal_account_details') }}</p>

            <v-text-field :rules="[value => !!value || 'Full Name required.']"
              :label="$t('full_name')"
              :hint="$t('full_name_paypal_hint')"
              persistent-hint
              v-model="paypalDetails.name"
            />

            <v-text-field :rules="[value => !!value || 'Paypal\'s E-mail required.']"
              :label="$t('paypal_email')"
              :hint="$t('paypal_email_hint')"
              persistent-hint
              v-model="paypalDetails.email"
            />
          </v-form>

          <v-btn color="primary" @click="updateDetails()" class="my-2">{{ $t('continue') }}</v-btn>
          <v-btn @click="e6 = 0" class="ma-2" text>Back</v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 2" step="2">{{ $t('choose_cashout_amount') }}</v-stepper-step>

        <v-stepper-content step="2">
          <PointStepper 
            :cashOutDetails="cashOutDetails"
          />    

          <v-btn color="primary" @click="e6 = 3">{{ $t('continue') }}</v-btn>
          <v-btn @click="e6 = 1" text class="ma-2">{{ $t('back') }}</v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 3" step="3">{{ $t('confirm_selections') }}</v-stepper-step>

        <v-stepper-content step="3">
          <v-form ref="form">
            <p>{{$t('confirm_details')}}</p>

            <v-text-field :rules="[value => !!value || 'Full Name required.']"
              :label="$t('full_name')"
              v-model="paypalDetails.name"
              readonly
            />

            <v-text-field
              :label="$t('paypal_email')"
              v-model="paypalDetails.email"
              :rules="emailRules"
              readonly
            />

            <v-text-field
              :label="$t('points_to_redeem')"
              v-model="cashOutDetails.points"
              readonly
            />

            <v-text-field
              :prefix="cashOutDetails.currency"
              :label="$t('cashout_amount')"
              v-model="cashOutDetails.amountInCurrency"
              readonly
            />

            <v-btn color="primary" @click="rewardCashoutPaypal()">{{ $t('confirm') }}</v-btn>
            <v-btn @click="e6 = 2" text>{{ $t('back') }}</v-btn>
          </v-form>
        </v-stepper-content>

        <v-stepper-step step="4">{{$t('thank_you')}}</v-stepper-step>
        <v-stepper-content step="4">
          <p>{{ $t('points_confirmation_email', { 0: cashOutDetails.points } ) }}</p>
        </v-stepper-content>
      </v-stepper>
    </div>
    <div v-else>
      <p v-html="$t('cashout_paypal_not_available_reasons')"></p>
    </div>
  </v-container>
</template>

<script>
import { DataService } from "../../services/DataService";
import PointStepper from "../BankAccount/pointStepper.vue"

const dataService = new DataService();
export default {
  components: { 
    PointStepper
  },
  data() {
    return {
      e6: 0,
      loaded: false,
      loading: false,
      paypalDetails: {},
      cashOutDetails: {
        points: 0,
        minToRedeem: 0,
        pointsPerUnit: 0,
        amountInCurrency: 0,
        currency: null,
      },
      countryConversion: {},
      hasRedeemed: false,
      emailRules: [
        v => !!v || `Paypal ${this.$t("email")} ${this.$t('required')}`,
        v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || this.$t("email_valid")
      ],
    }
  },

  watch: {
    'cashOutDetails.points': function(){      
      let pointConversion = this.cashOutDetails.minToRedeem + (this.cashOutDetails.multiplier * this.cashOutDetails.multiplierPoints)
      this.cashOutDetails.amountInCurrency = Math.floor(pointConversion * this.cashOutDetails.valuePerPoint)
    }
  },

  async mounted() {
    this.$store.state.loader = true
    await this.getConversionData()
        
    if (this.$store.state.userProfile.paypalDetails && Object.keys(this.$store.state.userProfile.paypalDetails).length > 0 && this.$store.state.userProfile.paypalDetails.country == this.paypalDetails.country) {
      this.paypalDetails = this.$store.state.userProfile.paypalDetails
    } else { 
      this.paypalDetails.name = this.$store.state.userProfile.name
      this.paypalDetails.email = this.$store.state.userProfile.email
    }
    this.initCashOutDetails()
    this.$store.state.loader = false
    this.loaded = true
  },

  methods: {
    initCashOutDetails() {
      this.cashOutDetails = Object.assign(this.cashOutDetails, this.countryConversion)
      this.cashOutDetails.points = this.cashOutDetails.minToRedeem
      this.cashOutDetails.totalPoints = this.$store.state.userProfile.totalPoints
      this.cashOutDetails.multiplier = 0
    },
    hasMetRedemptionCondition() {             
      return this.$store.state.userProfile.totalPoints >= (this.countryConversion.minToRedeem) || this.hasRedeemed == true ? true : false
    },
    updateDetails() {
      if (this.$refs.form.validate()) {
        let data = {
          userId: this.$store.state.userProfile._id,
          paypalDetails: this.paypalDetails,
        }
        dataService.updateMobileUser(this.$store.state.token, data)
        this.e6 = 2
      }
    },
    async getConversionData() {
      let data = await dataService.getConfig(`paypalCashoutConversion`)
      let country = this.$store.state.userProfile.demographics.find(demographic => demographic.title == "Country")
      this.countryConversion = country.answer && data[`PayPal_${country.answer}`] ? data[`PayPal_${country.answer}`] : data["PayPal"]
    },
    rewardCashoutPaypal() {
      this.$store.state.loader = true
      dataService.rewardCashoutPaypal(this.$store.state.token, { cashoutData: { ...this.paypalDetails, ...this.cashOutDetails } }).then(res => {
        if (res.error != true) {
          this.hasRedeemed = true
          this.e6 = 4
          this.$store.state.loader = false
          this.$store.state.userProfile = res.userProfile
        } else {
          if (res.redirect) {
            this.$router.push({ name: res.redirect, params: { deactivationMessage: true } })
          }

          this.$store.state.loader = false
          this.$store.state.snackBarText = this.$t(res.message)
          this.$store.state.showSnackBar = true
        }
      })
    }
  }
}
</script>